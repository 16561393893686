import React from "react";
import styled from "styled-components";
import HanburgerIcon from "./hamburger-icon";

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  open: boolean;
}

const StyledBtn = styled.button`
  border: none;
  background-color: transparent;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;

  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: ${({ theme }) => theme.colors.white};
`;

interface HamburgerProps {
  open: boolean;
}

const HanburgerBtn = ({ onClick, open }: Props) => (
  <StyledBtn onClick={onClick}>
    Meny
    <HanburgerIcon open={open} />
  </StyledBtn>
);

export default HanburgerBtn;
