import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import NavMenu from "./nav";
import Logo from "../../images/svgs/logga.svg";
import ContentWrapper from "../content-wrapper";

export interface Props {
  siteTitle?: string;
}

const StyledHeader = styled.header`
  background-color: #130804;
`;

const StyledH1 = styled.h1`
  margin: 0;
  font-size: 100%;
`;

const StyledLogo = styled(Logo)`
  height: auto;
  width: 260px;
  max-width: 100%;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: inline-block;
  margin: 10px 0;
`;

const Header = ({ siteTitle }: Props) => (
  <StyledHeader id="hem">
    <ContentWrapper>
      <StyledH1 aria-label={siteTitle}>
        <StyledLink to="/">
          <StyledLogo />
        </StyledLink>
      </StyledH1>
    </ContentWrapper>
    <NavMenu />
  </StyledHeader>
);

export default Header;
