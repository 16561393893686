import React, { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode | ReactNode[];
  textAlign?: "left" | "center" | "right";
}

const StyledParagraph = styled.p<Props>`
  line-height: 180%;
  font-size: ${({ theme }) => theme.fonts.body.sizes.large};
  margin-top: 0;
  margin-bottom: 2em;
  text-align: ${({ textAlign }) => textAlign};
`;

const Paragraph = ({ children, textAlign = "left" }: Props) => (
  <StyledParagraph textAlign={textAlign}>{children}</StyledParagraph>
);

export default Paragraph;
