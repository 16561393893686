import React, { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode | ReactNode[];
}

const StyledSectionHeading = styled.h2`
  font: ${({ theme }) => theme.fonts.headings.regular};
  font-size: 250%;
`;

const SectionHeading = ({ children }: Props) => (
  <StyledSectionHeading>{children}</StyledSectionHeading>
);

export default SectionHeading;
