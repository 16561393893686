export const screenSizes = {
  xxSmall: 350,
  xSmall: 438,
  small: 550,
  medium: 650,
  large: 700,
  xLarge: 1350,
  xxLarge: 1700,
  unit: "px",
};

const colorPalette = {
  primary: "#dc541b",
  secondary: "#dbd1cc",
  black: "#130804",
  white: "white",
};

export default {
  fonts: {
    body: {
      regular: `100% "Open Sans", sans-serif`,
      italic: `italic 100% "Open Sans", sans-serif`,
      bold: `100% bold "Open Sans", sans-serif`,
      sizes: {
        normal: "100%",
        large: "120%",
      },
    },
    headings: {
      regular: `1em "Josefin Sans Regular", "Open Sans", sans-serif`,
      bold: `1em "Josefin Sans Bold", "Open Sans", sans-serif`,
    },
  },
  colors: {
    ...colorPalette,
    tinted: colorPalette.secondary,
  },
  screens: {
    xxSmallMin: `(min-width: ${screenSizes.xxSmall}${screenSizes.unit})`,
    xxSmallMax: `(max-width: ${screenSizes.xxSmall - 1}${screenSizes.unit})`,
    xSmallMin: `(min-width: ${screenSizes.xSmall}${screenSizes.unit})`,
    xSmallMax: `(max-width: ${screenSizes.xSmall - 1}${screenSizes.unit})`,
    smallMin: `(min-width: ${screenSizes.small}${screenSizes.unit})`,
    smallMax: `(max-width: ${screenSizes.small - 1}${screenSizes.unit})`,
    mediumMin: `(min-width: ${screenSizes.medium}${screenSizes.unit})`,
    mediumMax: `(max-width: ${screenSizes.medium - 1}${screenSizes.unit})`,
    largeMin: `(min-width: ${screenSizes.large}${screenSizes.unit})`,
    largeMax: `(max-width: ${screenSizes.large - 1}${screenSizes.unit})`,
    xLargeMin: `(min-width: ${screenSizes.xLarge}${screenSizes.unit})`,
    xLargeMax: `(max-width: ${screenSizes.xLarge - 1}${screenSizes.unit})`,
    xxLargeMin: `(min-width: ${screenSizes.xxLarge}${screenSizes.unit})`,
    xxLargeMax: `(max-width: ${screenSizes.xxLarge - 1}${screenSizes.unit})`,
  },
};
