import React, { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  open: boolean;
  children: ReactNode | ReactNode[];
}

const StyledNavContent = styled.div<Props>`
  @media ${({ theme }) => theme.screens.xLargeMax} {
    display: ${({ open }) => (!open ? "none" : null)};
    background-color: ${({ theme }) => theme.colors.black};
    top: 100%;
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: -1;
  }
`;

const NavContent = ({ open, children }: Props) => (
  <StyledNavContent open={open}>{children}</StyledNavContent>
);

export default NavContent;
