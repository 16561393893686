import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Hamburger from "../hamburger";
import ContentWrapper from "../../content-wrapper";
import NavContent from "./nav-content";
import Menu from "./menu";
import { debounce } from "../../../utils/debounce";

export interface Page {
  name: string;
  id: string;
}

interface Props {
  pages?: Page[];
}

const StyledNav = styled.nav`
  z-index: 1;
  position: relative;
`;

const StyledStickyNav = styled(StyledNav)`
  position: fixed;
  width: 100%;
  top: 0;
  background-color: ${({ theme }) => theme.colors.black};
`;

const dummyProps = [
  {
    name: "Hem",
    id: "hem",
  },
  {
    name: "Om minnesfonden",
    id: "om-fonden",
  },
  {
    name: "Om Eva",
    id: "om-eva",
  },
  {
    name: "Bidra",
    id: "bidra",
  },
  {
    name: "Kontakt",
    id: "kontakt",
  },
];

const NavMenu = ({ pages = dummyProps }: Props) => {
  const [menuOpen, toggleMenu] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const navElement = useRef(null);

  const handleScroll = () => {
    window.scrollY > navElement?.current?.offsetTop
      ? setIsSticky(true)
      : setIsSticky(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", debounce(handleScroll, 10));
    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, [debounce, handleScroll]);

  const navContent = (
    <>
      <ContentWrapper>
        <Hamburger
          open={menuOpen}
          onClick={() => toggleMenu(prevState => !prevState)}
        />
      </ContentWrapper>
      <NavContent open={menuOpen}>
        <Menu pages={pages} closeMenu={() => toggleMenu(false)} />
      </NavContent>
    </>
  );

  let stickyNav = null;
  if (isSticky) {
    stickyNav = <StyledStickyNav>{navContent}</StyledStickyNav>;
  }
  return (
    <>
      <StyledNav ref={navElement}>{navContent}</StyledNav>
      {stickyNav}
    </>
  );
};

export default NavMenu;
