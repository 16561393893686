import React, { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode | ReactNode[];
}

const StyledContentWrapper = styled.div`
  width: 45rem;
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
`;

const ContentWrapper = ({ children }: Props) => (
  <StyledContentWrapper>{children}</StyledContentWrapper>
);

export default ContentWrapper;
