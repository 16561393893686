import React, { MouseEvent } from "react";
import styled from "styled-components";
import ScrollSpy from "react-scrollspy";
import MenuItem from "./menu-item";
import { Page } from "./";

interface Props {
  pages: Page[];
  closeMenu?: (event: MouseEvent<HTMLAnchorElement>) => void;
}

//aka styled.ul
const StyledNavMenu = styled(ScrollSpy)`
  @media ${({ theme }) => theme.screens.xLargeMin} {
    display: flex;
    justify-content: center;
  }
`;

export const currentClassName = "active";

const Menu = ({ pages, closeMenu }: Props) => {
  const navItems = pages.map(page => (
    <MenuItem page={page} key={`page-link-${page.id}`} onClick={closeMenu} />
  ));
  return (
    <StyledNavMenu
      offset={-20}
      currentClassName={currentClassName}
      items={pages.map(page => page.id)}
    >
      {navItems}
    </StyledNavMenu>
  );
};

export default Menu;
