import React from "react";
import styled from "styled-components";
import HamburgerBtn from "./hamburger-btn";

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  open: boolean;
}

const StyledContainer = styled.div`
  padding: 2px;
  display: flex;
  justify-content: flex-end;
  @media ${({ theme }) => theme.screens.xLargeMin} {
    & {
      display: none;
    }
  }
`;

const Hamburger = ({ onClick, open }: Props) => (
  <StyledContainer>
    <HamburgerBtn onClick={onClick} open={open} />
  </StyledContainer>
);

export default Hamburger;
