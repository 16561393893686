import React from "react";
import { createGlobalStyle } from "styled-components";
import JosefinSansRegular from "../fonts/JosefinSans-Regular.ttf";
import JosefinSansBold from "../fonts/JosefinSans-Bold.ttf";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Josefin Sans Regular";
    src: url(${JosefinSansRegular}) format("opentype");
  }

  @font-face {
    font-family: "Josefin Sans Bold";
    src: url(${JosefinSansBold}) format("opentype");
  }
  
  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }

  body {
    margin: 0;
    font: ${({ theme }) => theme.fonts.body.regular};
    text-align: center;
  }

  * {
    box-sizing: border-box;
  }

  button {
    appearance: none;
    cursor: pointer;
    font-size: 1rem;
    font: ${({ theme }) => theme.fonts.headings.bold};
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  img {
    max-width: 100%;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
  }
`;

const GlobalStyles = () => <GlobalStyle />;

export default GlobalStyles;
