import React from "react";
import styled from "styled-components";
import Butterfly from "../images/svgs/butterfly.svg";
import ContentWrapper from "./content-wrapper";

interface Props {
  siteTitle: string;
}

const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  padding-top: 60px;
  padding-bottom: 97px;
  font-size: 1.2em;
`;

const StyledCopyRight = styled.div`
  margin: 20px 0;
`;

const Footer = ({ siteTitle }: Props) => (
  <StyledFooter>
    <Butterfly style={{ height: "20px" }} />
    <ContentWrapper>
      <StyledCopyRight>
        © {new Date().getFullYear()} {siteTitle}
      </StyledCopyRight>
      <a href="mailto:evasminnesfond@gmail.com">evasminnesfond@gmail.com</a>
    </ContentWrapper>
  </StyledFooter>
);

export default Footer;
