import React from "react";
import styled from "styled-components";

export interface Props {
  tinted?: boolean;
}

const StyledTriangle = styled.div<Props>`
  border-top: 80px solid
    ${({ tinted, theme }) =>
      tinted ? theme.colors.tinted : theme.colors.white};
  border-left: 100px solid transparent;
  border-right: 100px solid transparent;
  width: 0;
  height: 0;
  margin-left: auto;
  margin-right: auto;
`;

const Triangle = (props: Props) => <StyledTriangle {...props}></StyledTriangle>;

export default Triangle;
