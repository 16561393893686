import React from "react";
import styled from "styled-components";

interface Props {
  open: boolean;
}

const StyledHamburger = styled.span`
  position: relative;
  width: 55px;
  height: 36px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  margin-left: 5px;
`;

const StyledHamburgerParts = styled.span`
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
`;

const StyledHamburgerBreadTop = styled(StyledHamburgerParts)<Props>`
  top: ${({ open }) => (open ? "18px" : "0px")};
  transform: ${({ open }) => (open ? "rotate(135deg)" : "rotate(0deg)")};
`;

const StyledHamburgerMeat = styled(StyledHamburgerParts)<Props>`
  top: ${({ open }) => (open ? "0px" : "13px")};
  opacity: ${({ open }) => (open ? "0" : 1)};
`;

const StyledHamburgerBreadBottom = styled(StyledHamburgerParts)<Props>`
  top: ${({ open }) => (open ? "18px" : "26px")};
  transform: ${({ open }) => (open ? "rotate(-135deg)" : "rotate(0deg)")};
`;

const HanburgerIcon = ({ open }: Props) => (
  <StyledHamburger>
    <StyledHamburgerBreadTop open={open}></StyledHamburgerBreadTop>
    <StyledHamburgerMeat open={open}></StyledHamburgerMeat>
    <StyledHamburgerBreadBottom open={open}></StyledHamburgerBreadBottom>
  </StyledHamburger>
);

export default HanburgerIcon;
