import React, { MouseEvent } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Page } from "./";
import { currentClassName } from "./menu";

interface Props {
  page: Page;
  className?: string;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
}

const StyledNavMenuItem = styled.li`
  @media ${({ theme }) => theme.screens.xLargeMax} {
    margin-top: 1em;
  }
  @media ${({ theme }) => theme.screens.xLargeMin} {
    width: 16.5rem;
  }
`;

const StyledNavMenuItemLink = styled(Link)`
  color: white;
  display: block;
  text-decoration: none;
  padding: 20px 0;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font: ${({ theme }) => theme.fonts.headings.bold};

  @media ${({ theme }) => theme.screens.xLargeMax} {
    font-size: 130%;
  }
  @media ${({ theme }) => theme.screens.xLargeMin} {
    font-size: 110%;
  }

  &:not(.${() => currentClassName}) {
    color: ${({ theme }) => theme.colors.white};
  }

  &.${() => currentClassName} {
    color: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const MenuItem = ({ page, className, onClick }: Props) => (
  <StyledNavMenuItem>
    <StyledNavMenuItemLink
      className={className}
      to={`#${page.id}`}
      onClick={onClick}
    >
      {page.name}
    </StyledNavMenuItemLink>
  </StyledNavMenuItem>
);

export default MenuItem;
