/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactNode } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled, { ThemeProvider } from "styled-components";
import myTheme from "../styles/theme";

import GlobalStyles from "../styles/global-styles";
import Header from "./header";
import Footer from "./footer";

export interface Props {
  children: ReactNode | ReactNode[];
}

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const StyledMain = styled.main`
  flex-grow: 1;
`;

const Layout = ({ children }: Props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={myTheme}>
      <GlobalStyles />
      <StyledLayout>
        <Header siteTitle={data.site.siteMetadata.title} />
        <StyledMain>{children}</StyledMain>
        <Footer siteTitle={data.site.siteMetadata.title} />
      </StyledLayout>
    </ThemeProvider>
  );
};

export default Layout;
