import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import Triangle, { Props as TriangleProps } from "./triangle";

interface Props extends TriangleProps, HTMLAttributes<HTMLElement> {
  displayArrow?: boolean;
}

const StyledSection = styled.section<Props>`
  position: ${({ displayArrow }) => (displayArrow ? "relative" : null)};
  background-color: ${({ tinted, theme }) =>
    tinted ? theme.colors.tinted : null};
  padding-bottom: 50px;
`;

const Section = ({
  children,
  tinted = false,
  displayArrow,
  ...props
}: Props) => {
  let arrow = null;
  if (displayArrow) {
    arrow = <Triangle tinted={!tinted} />;
  }
  return (
    <StyledSection tinted={tinted} displayArrow={displayArrow} {...props}>
      {arrow}
      {children}
    </StyledSection>
  );
};

export default Section;
